body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

.city {
   font-size: 40px;
   text-align: left;
   padding-top: 20px;
}
.row {
   display: flex;
   flex-wrap: wrap;
}
.col {
   flex: 1;
   min-width: 140px;
   text-align: center;
   vertical-align: middle;
}
.temp {
   padding-top: 25px;
   padding-left: 10px;
   text-align: center;
   font-size: 45px;
   font-weight: 500;
   letter-spacing: -3px;
}
.fonts_big {
   color: beige;
   text-shadow: 5px 5px 11px #111;
   font-weight: 600;
}
.fonts_med {
   color: #111;
   text-shadow: 3px 3px 8px #eee;
}
.med_font {
   font-size: 3vh;
}
.window {
   min-width: 300px;
   min-height: 500px;
   padding: 10px;
   border-radius: 10px;
   background: rgba(5, 5, 5, 0);
}
.search_wrapper {
   background: rgba(5, 5, 5, 0);
   text-align: center;
}
.search_box {
   min-width: 240px;
   font-size: 20px;
   padding: 10px;
   border-radius: 20px;
   border: 2px solid white;
   background: rgba(5, 5, 5, 0.1);
   color: white;
}
.cf_tog {
   min-width: 140px;
   font-size: 20px;
   padding: 5px;
   border-radius: 20px;
   border: 2px solid white;
   background: rgba(5, 5, 5, 0.1);
   color: white;
}

.go_wrapper {
   z-index: 10;
   margin-left: 37px;
   margin-top: 0px;
   width: 50px;
   height: 50px;
}

.go_button {
   background-color: #ccc;
   border: #dddddd 3px solid;
   color: #fff;
   font-weight: 500;
   border-radius: 23px;
   width: 65px;
   height: 48px;
}
.go_button:hover {
   background-color: #ddd;
   border: #919191 2px solid;
   color: #333;
   cursor: pointer;
}
.bg_image {
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
}
.sb {
   border: 1px dotted red;
   padding: 3px;
}
/* centering */
.vertical-center {
   margin: 0;
   position: absolute;
   top: 25%;
   -ms-transform: translateY(-25%);
   transform: translateY(-25%);
}
.center-inner {
   display: table;
   margin: 0 auto;
}

.center-outer {
   width: 100%;
}
.center {
   margin: auto;
   text-align: center;
}
.three {
   position: relative;
   top: 50%;
   right: 50%;
   transform: translate(50%, -50%);
   text-transform: uppercase;
   font-family: verdana;
   font-size: 3em;
   font-weight: 700;
   color: #f5f5f5;
   text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191,
      1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191,
      1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191,
      1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4),
      1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2),
      1px 30px 60px rgba(16, 16, 16, 0.4);
}
